<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Adjustments
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="adjustmentCollection"
        :search="search"
        :sort-by="['ad_number']"
        sort-desc="true"
        @click:row="handleClickItem"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th>
                <v-select
                  v-model="storageNameFilter"
                  :items="[
                    { storage_name: 'Select' },
                    ...storageNameCollection
                  ]"
                  item-text="storage_name"
                  item-value="storage_name"
                ></v-select>
              </th>
              <th>
                <v-select
                  v-model="nameFilter"
                  :items="[{ owner_name: 'Select' }, ...nameCollection]"
                  item-text="owner_name"
                  item-value="owner_name"
                ></v-select>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <AdjustmentModalForm
              ref="adjustmentlist"
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></AdjustmentModalForm>
            <v-spacer></v-spacer>

            <!-- <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox> -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <router-link
            :to="`/stock/adjustments/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi mdi-eye </v-icon>
          </router-link>
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import AdjustmentModalForm, { initialFormData } from "./AdjustmentModalForm";

// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "stock/adjustment/";

export default {
  name: "Adjustments",
  components: { AdjustmentModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      permissions: [],
      storageNameFilter: "Select",
      nameFilter: "Select",
      headers: [
        {
          text: "Adjustment ID",
          value: "ad_number"
        },
        {
          text: "Storage",
          value: "storage_name",
          filter: value => {
            if (this.storageNameFilter == "Select") return true;
            return value == this.storageNameFilter;
          }
        },
        {
          text: "User name",
          value: "owner_name",
          filter: value => {
            if (this.nameFilter == "Select") return true;
            return value == this.nameFilter;
          }
        },
        {
          text: "Date",
          value: "created_at"
        }
        // { text: "Status", value: "status" },
        // { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false
    };
  },

  computed: {
    ...mapGetters(["adjustmentCollection"]),
    storageNameCollection() {
      return this.adjustmentCollection.map(item => {
        return item.storage_name;
      });
    },
    nameCollection() {
      return this.adjustmentCollection.map(item => {
        return item.owner_name;
      });
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function(id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions(["fetchAdjustment"]),
    editItem(id) {
      this.modalData.editedId = id;
      this.modalData.dialog = true;
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedId = null;
      this.fetchAdjustment();

      if (this.$route.name !== "adjustments")
        this.$router.push({ name: "adjustments" });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchAdjustment();
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/adjustments/${item.id}`);
      }
    },

    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find(x => x.name == "stock.adjustment." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          item => item.name.indexOf("adjustment") > -1
        );
      }
    }
  },

  async mounted() {
    if (!this.$refs.adjustmentlist.handleCloseModalForm) {
      location.reload();
    }
    this.fetchAdjustment();
    this.setPermissions();
  }
};
</script>
